<template>
  <v-container fluid>
    <Loading :loading="!loaded" text="Henter materialeliste" />

    <v-navigation-drawer
      v-model="showPriceHistory"
      app
      right
      temporary
      width="50%"
    >
      <PartCustomerPriceHistory
        :records="priceHistory"
        @close="showPriceHistory = false"
      />
    </v-navigation-drawer>

    <div v-if="loaded">
      <v-row>
        <v-col>
          <v-card-title
            primary-title
            class="text-lg-h4"
          >
            {{ $t('parts.list.title') }}
          </v-card-title>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="model.query.textSearch"
                  :label="$t('search.textSearch')"
                  @input="search"
                  autofocus
                  placeholder="Skriv for at søge"
                />
              </v-col>
              <v-col>
                <v-autocomplete
                  v-model="model.query.categoryGroups"
                  :items="model.metadata.categoryGroups"
                  item-text="text"
                  item-value="value"
                  :label="$t('search.groups')"
                  return-object
                  :search-input.sync="categoryGroupInput"
                  auto-select-first
                  small-chips
                  deletable-chips
                  multiple
                  outlined
                  @change="search"
                  dense
                  @input="categoryGroupInput = null"
                />
              </v-col>
              <v-col>
                <v-autocomplete
                  v-model="model.query.customerCategories"
                  :items="model.metadata.customerCategories"
                  item-text="text"
                  item-value="value"
                  :label="$t('search.customerCategories')"
                  return-object
                  :search-input.sync="customerCategoryInput"
                  auto-select-first
                  small-chips
                  deletable-chips
                  multiple
                  outlined
                  @change="search"
                  dense
                  @input="customerCategoryInput = null"
                />
              </v-col>
              <v-col>
                <v-autocomplete
                  v-model="model.query.categories"
                  :items="model.metadata.categories"
                  item-text="text"
                  item-value="value"
                  :label="$t('search.categories')"
                  return-object
                  :search-input.sync="categoryInput"
                  auto-select-first
                  small-chips
                  deletable-chips
                  multiple
                  outlined
                  @change="search"
                  dense
                  @input="categoryInput = null"
                />
              </v-col>
              <v-col>
                <v-autocomplete
                  v-model="model.query.manufacturers"
                  :items="model.metadata.manufacturers"
                  item-text="text"
                  item-value="value"
                  :label="$t('search.manufacturers')"
                  return-object
                  :search-input.sync="manufacturerInput"
                  auto-select-first
                  small-chips
                  deletable-chips
                  multiple
                  outlined
                  @change="search"
                  dense
                  @input="manufacturerInput = null"
                />
              </v-col>
              <v-col>
                <v-checkbox
                  v-model="model.query.onlyPreferredByCustomer"
                  label="Vis kun favoritter"
                  class="mt-0"
                  @change="search"
                />
              </v-col>
              <v-col>
                {{ $t('sorting.title') }}
                <v-radio-group
                  v-model="model.query.sorting"
                  @change="search"
                >
                  <v-radio
                    v-for="(sort,i) in model.metadata.sortings"
                    :key="i"
                    :label="sort.text"
                    :value="sort.value"
                  />
                </v-radio-group>
              </v-col>
            </v-row>              
          </v-form>
        </v-col>
      </v-row>

      <span class="font-italic font-weight-thin">{{ model.data.partsCount | formatNumber }} {{ $tc('parts.list.itemsFound', model.data.partsCount) }}</span>
      
      <v-progress-linear
        color="secondary"
        :active="searching"
        indeterminate
      />
      <v-card
        elevation="2"
        class="pa-3"
      >
        <!-- start part data -->
        <v-row
          v-for="(part, i) in model.data.parts"
          :key="i"
          class="partRow text-caption"
        >
          <v-col cols="2">
            <div>{{ part.categoryTitle }}</div>
            <v-icon
              :title="part.groupTitle"
            >
              {{ part.groupIcon }}
            </v-icon>
          </v-col>
          <v-col cols="2">
            <div class="d-flex justify-space-between">
              <div>Vare nr.</div>
              <clipboard-copy>{{ part.partNumber }}</clipboard-copy>
            </div>
            <div class="d-flex justify-space-between">
              <div>Kundevare nr.</div>
              <clipboard-copy>{{ part.customerPartNumber }}</clipboard-copy>
            </div>
            <div v-if="part.manufacturer" class="d-flex justify-space-between">
              <div>{{ $t('parts.list.manufacturer') }}</div>
              <div>{{ part.manufacturer }}</div>
            </div>
            <div v-if="part.manufacturerPartNumber" class="d-flex justify-space-between">
              <div>{{ $t('parts.list.type') }}</div>
              <div>{{ part.manufacturerPartNumber }}</div>
            </div>
          </v-col>
          <v-col cols="6">
            <div v-if="part.title" class="text-body-2 mb-3">
              {{ part.title }}
            </div>
            <div
              v-if="part.description"
              class="mb-2"
            >
              {{ part.description }}
            </div>
            <div v-if="part.customerPartDescription">
              <div class="font-italic">
                Beskrivelse: {{ part.customerPartDescription }}
              </div>
            </div>
          </v-col>
          <v-col cols="2">
            <div
              class="d-flex flex-column align-end justify-space-between"
              style="height: 100%; gap: 1rem;"
            >
              <div class="d-flex justify-end align-center">
                <v-checkbox
                  v-model="part.isPreferredByCustomer"
                  color="yellow"
                  small
                  hide-details
                  off-icon="mdi-star-outline"
                  on-icon="mdi-star"
                  class="mt-0 pt-0"
                  @change="toggleFavorite(part.id, $event)"
                />

                <v-btn
                  icon
                  class="ml-2"
                  @click="getPriceHistory(part.id)"
                >
                  <v-icon>
                    mdi-history
                  </v-icon>
                </v-btn>
              </div>
              <table style="width: 100%">
                <tbody>
                  <!-- <tr>
                    <td>Listepris pr. {{ part.unitTitle }}</td>
                    <td style="text-align: right;">
                      <v-chip
                        v-if="part.isNew"
                        label
                        small
                        outlined
                        color="warning"
                        class="mr-1"
                      >
                        Ny pris
                      </v-chip>
                      {{ $t('currency.dkk') }} {{ part.listPrice | formatNumberFixed2 }}
                    </td>
                  </tr>
                  <tr v-if="part.listPrice !== part.price">
                    <td>Rabat</td>
                    <td style="text-align: right;">{{ Number(((part.listPrice - part.price) / part.listPrice) * 100).toFixed(0) }}%</td>
                  </tr> -->
                  <tr class="font-weight-bold text-body-1">
                    <td>Pris pr. {{ part.unitTitle }}</td>
                    <td style="text-align: right;">{{ $t('currency.dkk') }} {{ part.price | formatNumberFixed2 }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-end align-center text-subtitle-1">
                
              </div>
            </div>
          </v-col>
        </v-row>
        <!-- start table footer pager -->
        <v-row>
          <!-- <v-col cols="4">
            <span>{{model.data.partsCount | formatNumber}} vare{{ model.data.partsCount == 1 ? '' : 'r'}} fundet</span>
          </v-col> -->
          <v-spacer />
          <v-col
            class="pageSizeSelector"
            cols="1"
            lg="1"
            md="2"
            sm="5"
            xs="12"
          >
            <v-select
              v-model="model.query.pageSize"
              :items="model.metadata.pageSizes"
              label="Page size"
              solo
              @change="search"
            />
          </v-col>
          <v-col
            cols="2"
            lg="1"
            md="1"
            sm="2"
            xs="2"
          />
          <v-col
            cols="4"
            md="4"
            sm="5"
            xs="12"
          >
            <v-pagination
              v-model="model.query.page"
              :length="model.data.pageCount"
              :total-visible="8"
              @input="search($event, true)"
            />
          </v-col>
          <v-col
            cols="2"
            lg="1"
            md="1"
            sm="2"
            xs="2"
          />
        </v-row>
      </v-card>
    </div>
  </v-container>
</template>

<style scoped>
.pageSizeSelector{min-width: 100px;}
.partRow .col {border-bottom:thin solid rgba(0,0,0,.12);}
</style>

<script>
import Loading from '@/components/Loading'
import PartCustomerPriceHistory from '@/components/part/PartCustomerPriceHistory'
import ClipboardCopy from '@/components/ClipboardCopy'

var searchTimeout = null;

export default {
  
  name: 'PartListCustomer',
  components: {
    Loading,
    PartCustomerPriceHistory,
    ClipboardCopy
  },
  data() {
     return {
      model: Object,
      loaded: false,
      saving: false,
      priceHistory: [],
      searching: false,
      showPriceHistory: false,
      manufacturerInput: null,
      categoryGroupInput: null,
      categoryInput: null,
      customerCategoryInput: null
    }
  },
  mounted() {
    this.$AuthService.api.get('customerpart/ListInitSearch', { params: this.$route.query} )
      .then(response => {
        this.model = response.data;
        this.loaded = true;
      });
  },
  methods: {
    search(input, includePage) {
      var vm = this;

      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(function () {
        vm.searching = true;

        const query = { ...vm.model.query };

        if (!includePage) {
          query.page = 1;
        }

        vm.$AuthService.api.post('customerpart/ListSearch', query)
        .then(response => {
          vm.model.data = response.data.data;
          vm.model.metadata.manufacturers = response.data.metadata.manufacturers;
          vm.model.metadata.categoryGroups = response.data.metadata.categoryGroups;
          vm.model.metadata.categories = response.data.metadata.categories;
          vm.model.metadata.customerCategories = response.data.metadata.customerCategories;
          vm.searching = false;
          vm.loaded = true;

          if (!includePage) {
            vm.model.query.page = 1;
          }
        });
      }, 450);
    },

    async getPriceHistory(id) {
      this.showPriceHistory = true;

      try {
        const response = await this.$AuthService.api.get(`customerpart/${id}/history`);

        this.priceHistory = response.data?.data?.details;
      } catch (error) {
        console.error(error);
        this.$notifier.showError({});
      }
    },

    async toggleFavorite(id, isPreferred) {
      this.saving = true;

      try {
        await this.$AuthService.api.put(`customerpart/${id}?isPreferred=${isPreferred}`);

        this.$notifier.showSuccess({ message: 'Vare markeret som favorit' });
      } catch (error) {
        console.error(error);
        this.$notifier.showError({});
      } finally {
        this.saving = false;
      }
    }
  }
}
</script>